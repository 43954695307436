import * as React from 'react';
import './styles.scss';
import {
  faCreditCard,
  faCalendar,
  faLock,
  faCheckCircle, faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import { Button, Spinner, InputGroup, Form, Table } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';
import { stateType } from '../../store';
import { useEffect, useRef, useState } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import * as Actions from '../../store/actions';
import BaseService from '../../services/RestService/BaseService';
import { currency, currencyEnum } from '../../shared/utills/currency';
import Formsy from 'formsy-react';
import ModalAdvice, { ModalProps } from '../ModalAdvice/ModalAdvice';
import { get, debounce, find, omit } from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize } from '../../shared/utills/StringsHelper';
import UserEntity from '../../shared/entities/UserEntity';
import NumberFormatFormsy from '../formsy/NumberFormatFormsy';
import RncEntity from '../../shared/entities/RncEntity';
import NumberFormat from 'react-number-format';
import LogoCarnet from '../../assets/carnet-logo.png';
import ModalForm from '../ModalForm/ModalForm';
import CurrencyEntity from '../../shared/entities/CurrencyEntity';
import BillsEntity from '../../shared/entities/BillsEntity';

export interface IPaymentCheckoutFormProps extends RouteComponentProps {
  clearBill: ()=>void;
  logoutAction: ()=>void;
  findBill: (query?: string) => Promise<void>;
  toggleFullPlan: (data: any) => any;
  payBill: (data: BillsEntity)=>any;
  configPayment: (data: any)=>any;
  getCurrency: (x: any)=>any;
  currencyData: CurrencyEntity ;
  loading: boolean;
  error: any;
  getRnc: (rnc: any) => void;
  loadingRnc: boolean;
  errorRnc: object | undefined
  dataRnc: RncEntity
}
enum TaxPlayerEnum {
  TAXPLAYER = 1,
  REGISTER = 2,
}
const PaymentCheckoutForm: React.FunctionComponent<IPaymentCheckoutFormProps> =
  (props) => {
    const bill = useSelector<stateType, BillsEntity | any>(({ billReducer }) => billReducer.bill);
    const resPay = useSelector<stateType, BillsEntity | any>(({ entityReducer }) => entityReducer.bill.create);
    const initBill = useSelector<stateType, BillsEntity>(({ entityReducer }) => entityReducer.bill.find.data);
    const originalBill = useSelector<stateType, BillsEntity | null>(({ billReducer }) => billReducer.originalBill);
    const user = useSelector<stateType, UserEntity | any>(({ authenticationReducer }) => authenticationReducer.user);
    const [data] = useState<BillsEntity>(bill);
    const [togeDisplay, setTogeDisplay] = useState<boolean>(true);
    const [sendPay, setSenPay] = useState<boolean>(false);
    const [requireNCF, setRequireNCF] = useState<boolean>(false);
    const [isValidRnc, setIsValidRnc] = useState<boolean>(false);
    const [isValidBill, setIsValidBill] = useState < boolean | undefined >(undefined);
    const [requestPayment, setRequestPayment] = useState<boolean>(false);
    const [paymentVerificationLoading, setPaymentVerificationLoading] = useState<boolean>(false);
    const [document, setDocument] = useState<string>('');
    const [typeRnc, setTypeRnc] = useState<TaxPlayerEnum>(TaxPlayerEnum.TAXPLAYER);
    const [typeRnc2, setTypeRnc2] = useState(0);
    const { currencyData, getCurrency, findBill, toggleFullPlan } = props;

    const [modalConfig, setModalConfig] = useState<ModalProps>({
      isOpen: false,
      title: '',
      subTitle: '',
      icons: 'faCheck',
      description: '',
      error: true,
      onClose: () => {}
    });
    const [isFormValid, setIsFormValid] = useState(false);
    const formRef: any = useRef(null);
    useEffect(() => {
      getCurrency(1);
    }, [getCurrency]);

    useEffect(() => {
      if (formRef.error && (formRef.error.name || formRef.error.code || formRef.error.location)) {
        formRef.current.updateInputsWithError({
          ...formRef.error
        });
        disableButton();
      }
    }, [formRef.error]);

    useEffect(() => {
      if (!bill) props.history.push('/pagos');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bill]);

    useEffect(() => {
      if (sendPay && (resPay.error || resPay.data)) {
        if (get(resPay, 'data.data.complete') === 'false' || !get(resPay, 'data')) {
          openModalError();
        } else {
          openModalSuccess();
        }
        setSenPay(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.error, resPay]);

    function disableButton() {
      setIsFormValid(false);
    }

    function enableButton() {
      setIsFormValid(true);
    }
    useEffect(() => {
      if (requireNCF && props.dataRnc) {
        setIsValidRnc(true);
      } else if (!requireNCF) {
        setIsValidRnc(true);
      } else {
        setIsValidRnc(false);
      }
    }, [requireNCF, props.dataRnc]);

    useEffect(() => {
      if (initBill && originalBill) {
        const machResult = initBill.serviceCharges && initBill.serviceCharges.map((i) => {
          return !!find(originalBill.serviceCharges, { id: i.id });
        });
        const machResultReverse = originalBill.serviceCharges && originalBill.serviceCharges.map((i) => {
          return !!find(initBill.serviceCharges && initBill.serviceCharges, { id: i.id });
        });
        requestPayment && setIsValidBill(!find(machResultReverse, false) && !find(machResult, false) && initBill.serviceCharges.length === originalBill.serviceCharges.length);
      }
    }, [initBill, originalBill, requestPayment]);

    useEffect(() => {
      if (isValidBill) {
        processToPay(formRef.current.getCurrentValues());
        setIsValidBill(undefined);
      }
      isValidBill !== undefined && !isValidBill && requestPayment && paymentVerificationLoading && openModalVerificationError();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValidBill, requestPayment, paymentVerificationLoading]);

    function handleOnchange(value: string) {
      if (value.replace(/[-()\s]/g, '').length >= 9) {
        props.getRnc({
          typeRnc,
          rnc: value.replace(/[-()\s]/g, '')
        });
        setDocument(value);
      }
    }

    function handleOnchangeChecked(e: React.ChangeEvent<HTMLInputElement>) {
      if (e.target.id === 'rnc-contri' && e.target.checked) {
        setTypeRnc(TaxPlayerEnum.TAXPLAYER);
      } else if (e.target.id === 'rnc-register' && e.target.checked) {
        setTypeRnc(TaxPlayerEnum.REGISTER);
      }
      setDocument('');
    }
    function handleOnchangeChecked2(e: React.ChangeEvent<HTMLInputElement>) {
      if (e.target.id === 'rnc-contri' && e.target.checked) {
        setTypeRnc2(TaxPlayerEnum.TAXPLAYER);
      } else if (e.target.id === 'rnc-register' && e.target.checked) {
        setTypeRnc2(TaxPlayerEnum.REGISTER);
      }
      setDocument('');
    }
    const delayedQuery = debounce(q => handleOnchange(q), 500);

    function processToPay(form: any) {
      setPaymentVerificationLoading(false);
      if (user?.verifiedData) {
        props.payBill(omit({
          ...data,
          cardInformation: form.cardNumber ? {
            ...form,
            amount: total
          } : undefined,
          paymentInformation: {
            paymentWays: [
              {
                currencyType: data.suggestedAccount.currencyType.id,
                amount: total,
                paymentMethod: 2,

                institutionalAccount: 1,
                referenceNumber: '',
                terminal: '',
                paymentGateway: 3
              }
            ],
            fiscalInformation: requireNCF ? {
              rnc: +document,
              ncfType: data.typesVoucherFiscal ? data.typesVoucherFiscal[0].id : 1,
              name: get(props, 'dataRnc.name', '')

            } : {}
          }
        }, ['typesVoucherFiscal']));
        setSenPay(true);
      } else {
        window.location.reload();
      }
    }
    function handleSubmit(form: any) {
      findBill(user.typeLink ? '?type=1' : '');
      setRequestPayment(true);
      setPaymentVerificationLoading(true);
    }

    const closeModal = () => {
      setModalConfig({
        ...modalConfig,
        isOpen: false
      });

      user.typeLink && window.location.replace('https://unphu.edu.do');
      user.typeLink && props.logoutAction();
      props.clearBill();
      props.history.push('/pagos');
    };

    const openModalSuccess = () => {
      setRequestPayment(false);
      setModalConfig({
        ...modalConfig,
        isOpen: true,
        icons: 'faCheck',
        title: 'Pago realizado exitosamente.',
        description: (<> La factura del pago realizado fue enviada a su correo {user.typeLink ? 'personal' : 'institucional'} (<b className="text-muted"> {`${user.email}`}</b>)
          {resPay.data.approvalCode ? ' Número de aprobación: ' : ''}
          <b>{resPay.data.approvalCode}</b></>),
        error: false
      });
    };

    const openModalError = () => {
      setRequestPayment(false);
      setModalConfig({
        ...modalConfig,
        isOpen: true,
        icons: 'faTimesCircle',
        title: 'Algo salio mal.',
        description: (<>{get(resPay, 'data.approvalCode') ? 'Número de aprobación: ' : get(resPay, 'error.message')}
          <b>{get(resPay, 'data.approvalCode')}</b></>),
        error: true
      });
    };
    const openModalVerificationError = () => {
      setRequestPayment(false);
      setModalConfig({
        ...modalConfig,
        isOpen: true,
        icons: 'faExclamation',
        title: 'Algo a cambiado.',
        description: 'Los servicios agregados en pantalla han cambiando. Verifique nuevamente los servicios a pagar y continué con el pago',
        error: true
      });
    };
    const hiddenCardInformation = data && Number(data.totals.amountPaid) === 0;
    const payAllWithCredit = data && Number(data.totals.balanceFavorAmountRemaining) >= Number(data.totals.grossAmount);
    const showRnc = data && (Number(data.totals.balanceFavorAmountApplied) > 0 || !hiddenCardInformation);
    const isDOP = data.suggestedAccount.currencyType.id === currencyEnum.DOP;
    const total = data && isDOP ? data.totals.amountToBillTotal : data.totals.minimumAmountFromXchangeRate;

    const disableToContinue = (total <= 0);

    return (
      <div className="PaymentCheckoutForm">
        <ModalAdvice {...modalConfig} onClose={ closeModal }/>
        <ModalForm />
        <div className="form-payments ">
          {/* Container form */}
          <div className="form-card1">

            <div className="col col-12 mb-4 d-flex justify-content-center btn-list">
              <Button onClick={() => setTogeDisplay(!togeDisplay)}>Ver lista de pagos</Button>
            </div>

            {/* List payments */}
            <div className="row">
              <div className= {`${togeDisplay && 'md-hidden'}  
              pay-list-container col-lg-8 col-md-7 col-sm-12 order-xs-2`}>
                <div className="container-title mb-4">
                  <h5>Lista de pagos</h5>
                </div>

                <div className="table-responsive">
                  {/* style table = striped  */}
                  <Table hover>
                    <thead>
                      <tr>
                        <th>Servicio</th>
                        <th>Beca</th>
                        <th>Monto</th>
                        <th>Descuento</th>
                        <th>Por pagar</th>
                        <th>Pendiente</th>
                      </tr>
                    </thead>
                    <tbody>
                      { data && data.serviceCharges && data.serviceCharges
                        .map((item, index) => {
                          return item.service.description !== 'Descuento' && (<tr key={index} className=" border-bottom">
                            <td><strong>{ item.service.name || item.service.description }</strong></td>
                            <td>{currency(item.scholarshipAmount || item.scholarshipAmount, currencyEnum[data.suggestedAccount.currencyType.id])}</td>
                            <td>{currency(item.grossAmount || item.grossAmount, currencyEnum[data.suggestedAccount.currencyType.id])}</td>
                            <td>{currency(item.amountDiscount || item.amountDiscount, currencyEnum[data.suggestedAccount.currencyType.id])} </td>
                            <td>{currency(item.amountToPay || item.amountToPay, currencyEnum[data.suggestedAccount.currencyType.id])} </td>
                            <td>{currency(item.amountPending || item.amountPending, currencyEnum[data.suggestedAccount.currencyType.id])}</td>
                          </tr>);
                        })}
                    </tbody>
                  </Table>
                </div>
              </div>

              {/* form container payments */}
              <div className="form-payments-card shadow col-lg-4 col-md-5 col-sm-12 order-xs-1">
                {/* component form payment */}
                <div className={`${!showRnc && 'd-none'} container-rnc`}>
                  <Form.Group controlId="formBasicCheckbox">
                    <input type="checkbox"
                      id="requireNCF"
                      disabled={disableToContinue}
                      defaultChecked={requireNCF}
                      onChange={() => setRequireNCF(!requireNCF)}
                    />
                    <label htmlFor="requireNCF" className="ml-2">Requiere factura de crédito fiscal</label>
                  </Form.Group>
                  <InputGroup className={` ${(!requireNCF && 'd-none') || 'd-flex'} mb-3 justify-content-evenly` }>
                    <Form.Check
                      type="radio"
                      disabled={props.loadingRnc}
                      name="typeRnc"
                      id="rnc-contri"
                      onChange={handleOnchangeChecked}
                      defaultChecked
                      label="Contribuyente"
                    />
                    <Form.Check
                      type="radio"
                      disabled={props.loadingRnc}
                      name="typeRnc"
                      onChange={handleOnchangeChecked}
                      id="rnc-register"
                      label="Registrado"
                    />
                  </InputGroup>
                  <InputGroup className={` ${!requireNCF && 'd-none'} mb-3` }>

                    <NumberFormat
                      className="form-control"
                      type="text"
                      value={document}
                      name="document"
                      onChange={(e) => delayedQuery(e.target.value)}
                      placeholder="Escriba un RNC o Cédula válido"
                    />

                    {props.loadingRnc && <InputGroup.Append>
                      <Button
                        variant="light"
                        disabled
                        className="border bg-white">
                        <Spinner
                          as="span"
                          variant="success"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      </Button>{' '}
                    </InputGroup.Append>
                    }
                    {props.errorRnc && <InputGroup.Append>
                      <InputGroup.Text className="bg-red">
                        <FontAwesomeIcon icon={faTimesCircle} color="white"/>
                      </InputGroup.Text>
                    </InputGroup.Append>
                    }
                  </InputGroup>

                  {/* <InputGroup className={` ${(!requireNCF && 'd-none') || 'd-flex'} mb-3 justify-content-evenly` }>
                    <Form.Check
                      type="radio"
                      disabled={props.loadingRnc}
                      id="rnc-value1"
                      onChange={handleOnchangeChecked2}
                      defaultChecked
                      label="Valor1"
                    />
                    <Form.Check
                      type="radio"
                      disabled={props.loadingRnc}
                      name="typeRnc2"
                      onChange={handleOnchangeChecked2}
                      id="rnc-value2"
                      label="Valor2"
                    />
                  </InputGroup> */}
                </div>
                { props.dataRnc && <div className={`${!requireNCF && 'd-none'}`}>
                  <div className={'d-flex mb-3 justify-content-between border-bottom'}>
                    <span className="balance" >Nombre: </span>
                    <span><b>{props.dataRnc.name}</b></span>
                  </div>
                </div>}

                {/* Name User */}
                <div className={`mb-3 mt-4 ${!hiddenCardInformation && 'd-none'}`}>
                  <b className="balance">   {capitalize(user?.names)}</b>
                  <div>{capitalize(user?.enrollment)}</div>
                </div>

                <Formsy
                  onValidSubmit={handleSubmit}
                  onValid={enableButton}
                  onInvalid={disableButton}
                  ref={formRef}
                  // class ( d-none ) hide the credit card form
                  className={`${hiddenCardInformation && 'd-none'} mt-3 flex flex-col p-16 `}
                >

                  <NumberFormatFormsy
                    autoComplete="cc-number"
                    wrappedClass="col col-12"
                    className="form-control"
                    label="Número de tarjeta"
                    name="cardNumber"
                    type="text"
                    icon={faCreditCard}
                    required = {!disableToContinue}
                    disabled = {disableToContinue}
                    validations={{
                      isCardNumber: true
                    }}
                    validationErrors={{
                      isCardNumber: 'Número no válido'
                    }}
                    placeholder="XXXX XXXX XXXX XXXX"
                    format="#### #### #### ####"
                  />

                  <div className="container-input  input-group mb-4 mt-4">
                    <NumberFormatFormsy
                      autoComplete="cc-exp"
                      wrappedClass="col col-6 pl-0"
                      className="form-control"
                      label="Expiración"
                      name="expirationDate"
                      type="text"
                      disabled = {disableToContinue}
                      icon={faCalendar}
                      validations={{
                        minLength: 5,
                        isDateGreaterThanNow: true
                      }}
                      validationErrors={{
                        minLength: 'No es valido',
                        isDateGreaterThanNow: 'No es valida'
                      }}
                      required = {!disableToContinue}
                      placeholder="MM/YY"
                      format="##/##"
                      mask={['M', 'M', 'Y', 'Y']}
                    />
                    <NumberFormatFormsy
                      autoComplete="cc-csc"
                      wrappedClass="col col-6 pr-0"
                      className="form-control"
                      label="CVV"
                      name="cvv"
                      type="text"
                      disabled = {disableToContinue}
                      icon={faLock}
                      validations={{
                        isNumeric: true,
                        minLength: 3
                      }}
                      validationErrors={{
                        isNumeric: 'Solo numeros',
                        minLength: 'No es valido'
                      }}
                      required = {!disableToContinue}
                      format="###"
                      placeholder="000"
                    />
                  </div>
                </Formsy>

                {/* User balance */}

                {/* class ( d-none ) hide the user container balance */}
                <div className="mt-4 mb-5   info-user-balance">

                  <div className={`alert-payment ${!payAllWithCredit && 'd-none'}`} >
                    <FontAwesomeIcon icon={faCheckCircle} className="mr-2 icon" />
                      Monto a pagar cubierto 100%
                  </div >
                  {
                    disableToContinue
                      ? <div className={'alert-payment'} >
                        <FontAwesomeIcon icon={faCheckCircle} className="mr-2 icon" />
                          Monto a pagar cubierto 100%
                      </div >
                      : null as any
                  }
                  <div className="container-budget">
                    <div className="mb-3">
                      {data && Number(data.totals.balanceFavorAmountRemaining) > 0 &&
                      <div className="d-flex justify-content-between border-bottom">
                        <span className="balance" >Balance a favor: </span>
                        <span>{data && currency(data.totals.balanceFavorAmountRemaining, currencyEnum[data.suggestedAccount.currencyType.id])}</span>
                      </div>}
                      {data && (Number(data.totals.balanceFavorAmountApplied) + Number(data.totals.amountPaid)) > 0 &&
                      <div className="d-flex justify-content-between border-bottom">
                        <span className="balance" >Sub-total: </span>
                        <span>{data && currency(Number(data.totals.balanceFavorAmountApplied) + Number(data.totals.amountPaid.amountToPaidFromExchangeRate), currencyEnum[data.suggestedAccount.currencyType.id])}</span>
                      </div>}
                      {data && Number(data.totals.balanceFavorAmountApplied) > 0 &&
                      <div className="d-flex justify-content-between border-bottom">
                        <span className="balance">Balance a favor aplicado: </span>
                        <span>{data && currency(data.totals.balanceFavorAmountApplied, currencyEnum[data.suggestedAccount.currencyType.id])}</span>
                      </div>
                      }
                      {data && Number(data.totals.balanceFavorAmountApplied) > 0 &&
                      <div className="d-flex justify-content-between border-bottom">
                        <span className="balance" >Balance a favor restante:  </span>
                        <span>{data && currency(data.totals.balanceFavorAmountRemaining, currencyEnum[data.suggestedAccount.currencyType.id])}</span>
                      </div>
                      }
                      {!isDOP && currencyData && (
                        <div className="d-flex justify-content-between border-bottom">
                          <span className="balance" >Tasa de Cambio: </span>
                          <span> 1 USD$ = { currency(get(data, 'currencyExchange.saleValue', currencyData.TipoVenta))}</span>
                        </div>)}
                    </div>

                    <div className="amount-container d-flex justify-content-center">
                      <span className="number">Total a Pagar: {total && currency(total)}</span>
                    </div>
                  </div>

                </div>

                {/* Btn payment */}
                <div className="container-btn col d-flex flex-column col-12">
                  <button
                    onClick={() => handleSubmit(formRef.current.getCurrentValues())}
                    disabled={(!isFormValid && !hiddenCardInformation) || !isValidRnc || props.loading || requestPayment }
                    className="btn btn-primary submit-pay-button">
                    { isValidBill && !sendPay && 'Verificado'}
                    { !isValidBill && sendPay && 'Pagando'}
                    { paymentVerificationLoading && 'Verificando'}
                    { !props.loading && 'Pagar'}

                    {(props.loading || requestPayment) && <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="spinner-loading ml-2"
                    />}
                    <span className="sr-only">Loading...</span>
                  </button>

                  <div className="containet-img-carnet">
                    <span className="mr-2" >Este proceso es realizado con </span>
                    <img src={LogoCarnet} alt="Carnet"/>
                  </div>

                </div>

              </div>

            </div>

          </div>

        </div>

      </div>
    );
  };
const rncService = new BaseService('rnc');
const billService = new BaseService('bill');
const configPaymentService = new BaseService('configPayment');
const toggleFullPlanService = new BaseService('toggleFullPlan');
const currencyService = new BaseService('currency');
const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators({
    payBill: billService.create,
    configPayment: configPaymentService.create,
    toggleFullPlan: toggleFullPlanService.create,
    findBill: billService.find as any,
    clearBill: Actions.clearBill,
    logoutAction: Actions.logoutAction,
    getCurrency: currencyService.get,
    getRnc: rncService.create
  }, dispatch);
};
const mapState2Props = (state: stateType) => {
  return {
    loading: state.entityReducer.bill.create.loading || state.entityReducer.bill.find.loading,
    loadingRnc: state.entityReducer.rnc.create.loading,
    currencyData: state.entityReducer.currency.get.data,
    errorRnc: state.entityReducer.rnc.create.error,
    error: state.entityReducer.bill.create.error,
    dataRnc: state.entityReducer.rnc.create.data
  };
};

export default withRouter(connect(mapState2Props, mapDispatchToProps)(PaymentCheckoutForm));
